<template>
  <div>
    <vca-column class="event-card" :class="'event-' + event.type_of_event">
      <div class="event-title">
        <vca-row>
          <h3>
            {{ event.name }}
            <span v-if="event.crew.name != ''">({{ event.eventCrew }})</span>
          </h3>
          <div class="vca-right short">
            <img
              :src="event.eventIcon"
              :title="event.type_of_event_translation"
              :alt="event.type_of_event_translation"
            />
            <img
              :class="{ editable: event.location.name }"
              @click="showMap = true"
              v-if="event.hasLocation"
              src="@/assets/icons/map.png"
              :title="$t('button.map')"
              :alt="$t('button.map')"
            />
            <vca-share-icon
              v-if="event.isPublished"
              :url="event.shareURL"
              :text="
                $t('events.share.text', {
                  0: event.name,
                })
              "
            ></vca-share-icon>
          </div>
        </vca-row>
        <p class="event-subtitle">
          {{ event.type_of_event_translation }}
        </p>
      </div>
      <p>
        <span class="bold">{{ $t("table.header.state") }}:</span>
        <span> {{ event.event_state_text }}</span>
      </p>
      <p>
        <span class="bold">{{ $t("events.artists") }}:</span><br />{{
          event.eventArtists
        }}
      </p>
      <p>
        <span class="bold">{{ $t("events.crew.label") }}:</span><br />
        <span v-if="event.crew.name != ''">{{ event.eventCrew }}</span>
        <span v-else>{{ $t("events.list.crew.office_hh") }}</span>
      </p>
      <p>
        <span class="bold">{{ $t("events.location.title") }}:</span><br />{{
          event.eventLocation
        }}
      </p>
      <p>
        <span class="bold">{{ $t("events.time.application_period") }}:</span
        ><br />{{ applicationPeriod }}
      </p>
      <p>
        <span class="bold">{{ $t("events.time.event_period") }}:</span><br />{{
          eventPeriod
        }}
      </p>
      <div class="vca-center">
        <button
          class="vca-button-small"
          :title="$t('button.show')"
          @click="setCurrent(event)"
        >
          {{ $t("button.show") }}
        </button>
        <button
          v-if="canEdit || isEventAsp(event)"
          :title="$t('button.edit')"
          class="vca-button-small"
          @click="setCurrentEdit(event)"
        >
          {{ $t("button.edit") }}
        </button>
      </div>
    </vca-column>
    <vca-popup
      :show="showMap && event.location.place_id != ''"
      :title="$t('events.map')"
      @close="showMap = false"
    >
      <EventMap v-model="event" />
    </vca-popup>
  </div>
</template>
<script>
import EventMap from "@/components/events/EventMap";
import { mapGetters } from "vuex";
export default {
  name: "EventCard",
  components: { EventMap },
  data() {
    return {
      showMap: false,
      mappedLocation: [
        {
          ...this.event.location,
          ...{
            title: this.event.name,
            subtitle: this.event.type_of_event_translation,
          },
        },
      ],
    };
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  computed: {
    canEdit() {
      return (
        this.hasSystemPermission() ||
        (this.hasPoolPermission(this.poolEventPermissions) &&
          this.user.crew.crew_id == this.event.crew.id)
      );
    },
    eventPeriod() {
      return (
        this.datetime(this.event.start_at) +
        " - " +
        this.datetime(this.event.end_at)
      );
    },
    applicationPeriod() {
      return (
        this.date(this.event.application.start_date) +
        " - " +
        this.date(this.event.application.end_date)
      );
    },
    ...mapGetters({
      user: "user/current",
      hasPoolPermission: "user/roles/hasPoolPermission",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
  methods: {
    isEventAsp(value) {
      return (
        this.user && value.event_asp_id && this.user.id == value.event_asp_id
      );
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("events/current", null);
      } else {
        this.$store.commit("events/current", value);
      }
    },
    setCurrentEdit(value) {
      this.$router.push({ path: "/events/edit/" + value.id });
    },
  },
};
</script>
