<template>
  <vca-card>
    <div class="desktop-view">
      <vca-row style="position: relative; width: max-content; float: right">
        <button
          class="vca-button-small"
          @click="event_view = 'table'"
          :class="{ 'active-select': event_view == 'table' }"
        >
          {{ $t("events.list.list") }}
        </button>
        <button
          class="vca-button-small"
          @click="event_view = 'cards'"
          :class="{ 'active-select': event_view == 'cards' }"
        >
          {{ $t("events.list.cards") }}
        </button>
      </vca-row>
    </div>
    <h2>{{ $t("events.list.header") }}</h2>
    <p v-if="!session">{{ $t("events.list.info_nosession") }}</p>
    <p v-else>{{ $t("events.list.info") }}</p>
    <div class="mobile-view">
      <vca-row style="margin: 10px auto">
        <button
          style="margin: 0 auto"
          class="vca-button-small"
          @click="event_view = 'table'"
          :class="{ 'active-select': event_view == 'table' }"
        >
          {{ $t("events.list.list") }}
        </button>
        <button
          style="margin: 0 auto"
          class="vca-button-small"
          @click="event_view = 'cards'"
          :class="{ 'active-select': event_view == 'cards' }"
        >
          {{ $t("events.list.cards") }}
        </button>
      </vca-row>
    </div>
    <vca-card class="shadowed vca-center">
      <vca-column style="width: 100%">
        <vca-accordion class="desktop-view" :header="$t('events.list.filter')">
          <div slot="body">
            <EventFilter v-model="filter" />
          </div>
        </vca-accordion>
        <div class="mobile-view">
          <button class="vca-button" @click="do_filter = true">
            {{ $t("events.list.filter") }}
          </button>
          <vca-popup
            v-if="do_filter"
            :show="do_filter"
            :title="$t('events.list.filter')"
            @close="do_filter = false"
          >
            <EventFilter v-model="filter" />
          </vca-popup>
        </div>

        <div v-if="event_view == 'cards'">
          <EventCards @sort="sort" :list="getList()" :filter="filter" />
        </div>
        <EventTable
          @sort="sort"
          :list="getList()"
          :filter="filter"
          v-if="event_view == 'table'"
        />
      </vca-column>
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('events.popup.view', { 0: current.name })"
        @close="current = null"
      >
        <EventApplication @close="current = null" :event="current" />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EventFilter from "@/components/events/list/EventFilter";
import EventApplication from "@/components/events/application/EventApplication";
import EventTable from "@/components/events/EventTable";
import EventCards from "@/components/events/EventCards";
export default {
  name: "EventsList",
  components: { EventTable, EventCards, EventFilter, EventApplication },
  data() {
    return {
      store: "events/pg",
      do_filter: false,
      currentFilter: {},
      currentTextFilter: "",
    };
  },
  created() {
    if (!this.$route.params.id) {
      this.current = null;
    }

    if (Object.keys(this.filter).length == 0) {
      this.filter = {
        type: [],
        crews: [],
        entities: [],
        start_date: null,
        end_date: null,
        event_state: [],
        only_apply: true,
        missing_applications: false,
      };
    }

    if (
      this.hasPoolPermission(this.poolEventPermissions) ||
      this.hasSystemPermission()
    ) {
      this.filter.only_apply = false;
      this.filter.event_state = [
        "created",
        "requested_crew",
        "requested_internal",
        "published",
      ];
    } else {
      this.filter.event_state = ["published"];
    }

    if (
      this.hasPoolPermission(this.poolEventPermissions) ||
      this.hasSystemPermission() ||
      this.session
    ) {
      this.$store.dispatch({ type: "events/list" }).then(() => {
        if (this.$route.params.id) {
          this.current = this.list.find((el) => el.id == this.$route.params.id);
        }
        this.pg.currentSortDir = "desc";
        this.sort("start_at");
      });
    } else {
      this.$store.dispatch({ type: "events/public" }).then(() => {
        if (this.$route.params.id) {
          this.current = this.list.find((el) => el.id == this.$route.params.id);
        }
        this.pg.currentSortDir = "desc";
        this.sort("start_at");
      });
    }

    const type = this.hasSystemPermission() ? "crews/list" : "crews/public";
    this.$store.dispatch({ type: type });
    if (this.session) {
      this.$store
        .dispatch({ type: "events/participations/listUser" })
        .then((response) => {
          this.$store.commit(
            "events/participations/list",
            !response || response.length == 0 ? [] : response
          );
        });
    }
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    event_view: {
      get() {
        return this.$store.state.user.settings.current.event_view;
      },
      set(value) {
        this.$store.commit("user/settings/current", {
          ...this.settings,
          ...{ event_view: value },
        });
      },
    },
    ...mapGetters({
      session: "session",
      user: "user/current",
      list: "events/list",
      settings: "user/settings/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    current: {
      get() {
        return this.$store.state.events.current;
      },
      set(value) {
        this.$store.commit("events/current", value);
      },
    },
    emptyFilter() {
      return (
        (!this.pg.filter || this.pg.filter == "") &&
        this.filter.type.length == 0 &&
        this.filter.crews.length == 0 &&
        this.filter.entities.length == 0 &&
        this.filter.event_state.length == 0 &&
        this.filter.only_apply == false &&
        this.filter.missing_applications == false &&
        this.filter.start_date == null &&
        this.filter.end_date == null
      );
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
  },
  methods: {
    getList() {
      // If the list is empty, we have an empty array
      if (!this.list) {
        return [];
      }

      // If the current page is out of scope, set it to last page
      if (this.pg.currentPage > this.pg.pageCount) {
        this.$store.commit(this.store + "/prevPage");
      }

      // Create a list for filtering and start filtering
      var filteredList = this.list;
      filteredList = filteredList.filter((row, index) => {
        // Add a rank to the current entry
        row.rank = index + 1;

        row.isEventAsp =
          this.user && row.event_asp_id && this.user.id == row.event_asp_id;

        if (
          !this.hasPoolPermission(this.poolEventPermissions) &&
          !this.hasSystemPermission() &&
          !row.isEventAsp &&
          !row.isPublished
        ) {
          return false;
        }

        row.type_of_event_translation = this.$t(
          "events.eventtypes." + row.type_of_event + ".title"
        );

        // Map states here
        // published and end_date already passed gets mapped to "finished"
        // no pool or system permissions and state closed gets mapped to "finished"
        row.event_state_text = this.$t(
          "events.list.event_states." + row.mappedState
        );

        if (this.hasSystemPermission() && row.event_state.state == "closed") {
          row.event_state_text = this.$t("events.list.event_states.closed");
        }

        if (row.event_state.state == "requested") {
          row.event_state_text =
            row.event_state.internal_confirmation == ""
              ? this.$t("events.list.event_states.requested_internal")
              : this.$t("events.list.event_states.requested_crew");
        }

        row.applications = {
          confirmed: 0,
          requested: 0,
          rejected: 0,
          withdrawn: 0,
        };
        if (row.participations) {
          row.applications = {
            confirmed: row.participations.filter((el) => {
              return el.status == "confirmed";
            }).length,
            requested: row.participations.filter((el) => {
              return el.status == "requested";
            }).length,
            rejected: row.participations.filter((el) => {
              return el.status == "rejected";
            }).length,
            withdrawn: row.participations.filter((el) => {
              return el.status == "withdrawn";
            }).length,
          };
        }

        // If the filter is empty, everything is fine
        if (this.emptyFilter) {
          return true;
        }

        if (this.filter.event_state.length > 0) {
          if (!this.hasSystemPermission()) {
            if (!this.filter.event_state.includes(row.mappedState)) {
              return false;
            }
          } else {
            if (
              !this.filter.event_state.includes(row.event_state.state) &&
              !(
                this.filter.event_state.includes("requested_crew") &&
                row.event_state.state == "requested" &&
                row.event_state.crew_confirmation == ""
              ) &&
              !(
                this.filter.event_state.includes("requested_internal") &&
                row.event_state.state == "requested" &&
                row.event_state.internal_confirmation == ""
              )
            ) {
              return false;
            }
          }
        }

        if (
          this.filter.crews.length > 0 &&
          !this.filter.crews.find((el) => row.crew.id == el.value)
        ) {
          return false;
        }
        if (this.filter.entities.length > 0) {
          let entities = [...this.filter.entities];
          if (
            this.filter.entities.includes(
              this.$t("events.list.entity_filter.no_country")
            )
          ) {
            entities.push("");
          }

          if (!entities.includes(row.location.country)) {
            return false;
          }
        }

        if (
          this.filter.start_date &&
          new Date(this.filter.start_date * 1000) >=
            new Date(row.start_at * 1000)
        ) {
          return false;
        }

        if (
          this.filter.end_date &&
          new Date(this.filter.end_date * 1000) <= new Date(row.end_at * 1000)
        ) {
          return false;
        }

        if (
          this.filter.only_apply &&
          (new Date() <
            new Date(row.application.start_date * 1000).setUTCHours(
              0,
              0,
              0,
              0
            ) ||
            new Date() >
              new Date(row.application.end_date * 1000).setUTCHours(
                23,
                59,
                59,
                999
              ))
        ) {
          return false;
        }

        if (
          this.filter.missing_applications &&
          row.application.supporter_count <=
            row.participations.filter((pel) => {
              return pel.status == "confirmed";
            }).length
        ) {
          return false;
        }

        if (this.filter.type.length > 0) {
          if (!this.filter.type.includes(row.type_of_event)) {
            return false;
          }
        }
        return (
          row.id.toLowerCase().indexOf(this.pg.filter.toLowerCase()) > -1 ||
          (row.crew &&
            row.crew.name.toLowerCase().indexOf(this.pg.filter.toLowerCase()) >
              -1) ||
          row.name.toLowerCase().indexOf(this.pg.filter.toLowerCase()) > -1 ||
          row.eventLocation
            .toLowerCase()
            .indexOf(this.pg.filter.toLowerCase()) > -1 ||
          row.eventArtists.toLowerCase().indexOf(this.pg.filter.toLowerCase()) >
            -1
        );
      });

      // Set the length of the list to the current filtered list
      this.pg.listLength = filteredList.length == 0 ? 1 : filteredList.length;
      if (
        this.pg.listLength < this.pg.pageSize ||
        this.pg.pageSize > filteredList.length
      ) {
        // If the length is smaller than the current page size, we adjust the pagesize to the length of the filtered list
        this.pg.pageSize = this.pg.listLength;
      } else if (!this.sameFilter || this.initial) {
        // If the filter has changed, we resize the list
        this.initial = false;
        this.pg.pageSize =
          this.pg.currentPageSize > this.pg.listLength
            ? this.pg.listLength
            : this.pg.currentPageSize;
      }
      // Set the new filters
      if (!this.sameFilter) {
        this.currentTextFilter = this.pg.filter;
        this.currentFilter = JSON.parse(JSON.stringify(this.filter));
      }

      // Now we filter the list to the current pageSize and paginate it
      return filteredList.filter((row, index) => {
        let start = (this.pg.currentPage - 1) * this.pg.pageSize;
        let end = this.pg.currentPage * this.pg.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    sort(col) {
      // When sorting, we set the current page to 1
      this.$store.commit(this.store + "/firstPage");

      // If we have an empty list, return an empty array
      if (!this.list) {
        return [];
      }

      // Define the sorting direction, if current sort is the same as the column to sort, then switch direction
      if (this.pg.currentSort === col) {
        this.pg.currentSortDir =
          this.pg.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.pg.currentSort = col;
      }

      // Sort the list depending on the column and the direction
      this.list.sort((a, b) => {
        var final_key = this.pg.currentSort;

        // Sort nested objects if sorting column contains a dot (.)
        if (col.indexOf(".") !== -1) {
          var column = col.split(".");
          var len = column.length;
          var i = 0;
          while (i < len - 1) {
            a = a[column[i]];
            b = b[column[i]];
            i++;
            final_key = column[i];
          }
        }

        // Set zero 0 at the end
        // if (parseInt(a[final_key]) == 0) return 1;
        // if (parseInt(b[final_key]) == 0) return -1;

        // Comapre integer values
        if (
          a[final_key] === parseInt(a[final_key], 10) ||
          a[final_key] === parseFloat(a[final_key], 10)
        ) {
          return this.pg.currentSortDir === "desc"
            ? a[final_key] - b[final_key]
            : b[final_key] - a[final_key];
        }

        // Compare string values
        let modifier = 1;
        if (this.pg.currentSortDir === "desc") modifier = -1;
        if (a[final_key].toLowerCase() < b[final_key].toLowerCase())
          return -1 * modifier;
        if (a[final_key].toLowerCase() > b[final_key].toLowerCase())
          return 1 * modifier;
        return 0;
      });
    },
  },
};
</script>
<style lang="scss">
.active-select {
  color: white;
  background-color: $blue-dark;
  border: solid thin $blue-dark;
}
</style>
